import { bindable, inject }             from 'aurelia-framework';
import { I18N }                         from 'aurelia-i18n';
import { PLATFORM }                     from 'aurelia-pal';
import { BaseListViewModel }            from 'base-list-view-model';
import { AccountActivationsRepository } from 'modules/administration/account-activations/services/repository';
import { UserStatus }                   from 'modules/administration/models/user-status';
import { FilterFormSchema }             from 'modules/administration/users/filter-form-schema';
import { UsersRepository }              from 'modules/administration/users/services/repository';
import { AppContainer }                 from 'resources/services/app-container';
import { Downloader }                   from 'resources/services/downloader';

@inject(AppContainer, UsersRepository, I18N, Downloader, AccountActivationsRepository, FilterFormSchema)
export class ListUsers extends BaseListViewModel {

    listingId = 'administration-users-listing';

    @bindable headerTitle    = 'listing.administration.users';
    @bindable newRecordRoute = 'administration.users.create';
    @bindable repository;
    @bindable datatable;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param i18N
     * @param downloader
     * @param accountActivationsRepository
     * @param filterFormSchema
     */
    constructor(appContainer, repository, i18N, downloader, accountActivationsRepository, filterFormSchema) {
        super(appContainer);

        this.repository                   = repository;
        this.i18N                         = i18N;
        this.downloader                   = downloader;
        this.accountActivationsRepository = accountActivationsRepository;
        this.newRecordRoute               = appContainer.authenticatedUser.can(['administration.users.manage', 'administration.users.create']) ? this.newRecordRoute : null;
        this.filterFormSchema             = filterFormSchema;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('resources/views/default/list.html');
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'administration.users.manage',
            'administration.users.view',
        ]);
    }

    /**
     * Handles activate event
     */
    activate(params, routeConfig) {
        super.activate(params, routeConfig);

        this.defineDatatable();

        this.filterSchema = this.filterFormSchema.schema(this);
        this.filterModel  = this.filterFormSchema.model(this);
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            resource:   'resource.administration.user',
            repository: this.repository,
            edit:       'administration.users.edit',
            destroy:    'administration.users.destroy',
            actions:    [
                {
                    visible: (row) => row.status.id === UserStatus.PENDING,
                    action:  (row) => this.sendAccountActivationEmail(row),
                    tooltip: 'form.button.resend-account-activation-email',
                    icon:    'icon-user-check',
                },
            ],
            options:    [
                {
                    label:  'form.button.export-to-excel',
                    icon:   'icon-file-excel',
                    action: () => this.exportListing(this.i18N.tr('form.field.users'), !this.datatable.instance.selectedRows.length ? null : this.datatable.instance.selectedRows),
                },
            ],
            buttons:    [],
            selectable: true,
            sorting:    {
                column:    0,
                direction: 'asc',
            },
            columns:    [
                {
                    data:  'name',
                    name:  'users.name',
                    title: 'form.field.name',
                },
                {
                    data:  'email',
                    name:  'users.email',
                    title: 'form.field.email',
                },
                {
                    data:  'phone_number',
                    name:  'users.phone_number',
                    title: 'form.field.contact',
                },
                {
                    data:      'roles',
                    name:      'roles.name',
                    title:     'form.field.role',
                    type:      'processed',
                    processor: (row) => `<ul>` + row.roles.map((name) => `<li>${name}</li>`).join('') + `</ul>`,
                },
                {
                    data:  'status',
                    name:  'user_statuses.name',
                    title: 'form.field.status',
                    type:  'label',
                },
                {
                    data:            'created_at',
                    name:            'users.created_at',
                    title:           'form.field.created-at',
                    valueConverters: [
                        {
                            name: 'dateTimeFormat',
                        },
                    ],
                },
                {
                    data:            'inactivated_at',
                    name:            'users.inactivated_at',
                    title:           'form.field.inactivated-at',
                    valueConverters: [
                        {
                            name: 'dateTimeFormat',
                        },
                    ],
                },
                {
                    data:       'missing_consents_count',
                    name:       'missing_consents_count',
                    title:      'form.field.consents',
                    type:       'stoplight',
                    color:      (row) => row.missing_consents_count > 0 ? 'text-danger' : 'text-success',
                    searchable: false,
                },
            ],
        };
    }

    /**
     * Sends the given user's account activation email.
     *
     * @param {id}
     */
    sendAccountActivationEmail({ id }) {
        this.accountActivationsRepository
            .sendEmail(id)
            .then(response => {
                let notice = response.status === true ? 'successNotice' : 'dangerNotice';
                this.appContainer.notifier[notice](response.message);
            });
    }

}
