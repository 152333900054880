import { inject }                 from 'aurelia-framework';
import { BooleanOption }          from 'modules/administration/models/boolean-option';
import { User }                   from 'modules/administration/models/user';
import { UserStatus }             from 'modules/administration/models/user-status';
import { RolesRepository }        from 'modules/administration/roles/services/repository';
import { UserStatusesRepository } from 'modules/administration/user-statuses/services/repository';

@inject(RolesRepository, UserStatusesRepository)
export class FormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {
        status_id:            UserStatus.ACTIVE,
        receive_user_updates: BooleanOption.NO,
    };

    /**
     * Constructor
     *
     * @param rolesRepository
     * @param userStatusesRepository
     */
    constructor(rolesRepository, userStatusesRepository) {
        this.rolesRepository        = rolesRepository;
        this.userStatusesRepository = userStatusesRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {User}
     */
    model() {
        let model = new User();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns schema
     *
     * @param viewModel
     */
    schema(viewModel) {
        this.name = {
            type:  'text',
            key:   'name',
            label: 'form.field.name',
            size:  6,
        };

        this.email = {
            type:  'email',
            key:   'email',
            label: 'form.field.email',
            size:  3,
        };

        this.contact = {
            type:     'text',
            key:      'phone_number',
            label:    'form.field.contact',
            required: false,
            size:     3,
        };

        this.password = {
            type:     'password',
            key:      'password',
            label:    'form.field.password',
            required: false,
            size:     6,
            hidden:   !viewModel.model.is_local,
        };

        this.password_confirmation = {
            type:     'password',
            key:      'password_confirmation',
            label:    'form.field.password-confirmation',
            required: false,
            size:     6,
            hidden:   !viewModel.model.is_local,
        };

        this.status_id = {
            type:         'select2',
            key:          'status_id',
            label:        'form.field.status',
            size:         6,
            remoteSource: () => this.userStatusesRepository.active(),
        };

        this.receive_user_updates = {
            type:   'boolean-options-select',
            key:    'receive_user_updates',
            label:  'form.field.receive-message-unprofiled-logins',
            helper: 'helpers.administration.users.receive-message-unprofiled-logins',
            size:   6,
        };

        this.observations = {
            type:       'textarea',
            key:        'observations',
            label:      'form.field.observations',
            required:   false,
            size:       12,
            attributes: {
                maxlength: 65535,
            },
        };

        this.roles = {
            type:         'duallistbox',
            key:          'roles',
            label:        'form.field.general-menu-roles',
            size:         12,
            required:     false,
            remoteSource: () => this.rolesRepository.activeGeneralWithSystem(),
        };

        return [
            [this.name, this.email, this.contact],
            [this.password, this.password_confirmation],
            [this.status_id, this.receive_user_updates],
            [this.observations],
            [this.roles],
        ];
    }

    /**
     * Returns buttons schema
     *
     * @param viewModel
     * @return {*[][]}
     */
    globalButtonSchema(viewModel) {
        this.backButton = {
            type:       'button',
            label:      'form.button.back',
            action:     (event) => viewModel.redirectToRoute('administration.users.index'),
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-chevron-left',
                },
            },
        };

        this.clearButton = {
            type:       'button',
            label:      viewModel.create ? 'form.button.clear' : 'form.button.reverse-changes',
            action:     () => viewModel.resetForm(),
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-close2',
                },
            },
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.save',
            action:     (event) => viewModel.submit(),
            attributes: {
                class: 'btn btn-primary',
            },
            icon:       {
                attributes: {
                    class: 'icon-floppy-disk',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.backButton,
                this.clearButton,
                this.submitButton,
            ],
        };

        return [
            [this.buttons],
        ];
    }

}
